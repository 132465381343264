import "../scss/pages/HomePage.scss"

import React from "react"
import { graphql, Link } from "gatsby"
import Img from 'gatsby-image'

import Header from "../components/marketing/Header/Header.jsx"
import Footer from "../components/marketing/Footer/Footer.jsx"
import SEO from "../components/marketing/seo"
import BlogPostCard from "../components/marketing/BlogPostCard/BlogPostCard.jsx"
import MailingListSignup from "../components/marketing/MailingListSignup/MailingListSignup.jsx"
import MailingListSignupForm from "../components/marketing/MailingListSignupForm/MailingListSignupForm.jsx"


import RunwayIcon from '!svg-react-loader!../../content/assets/icn-runway.svg?name=RunwayIcon'
import ExpensesIcon from '!svg-react-loader!../../content/assets/icn-expense-tracking.svg?name=ExpensesIcon'
import TaxesIcon from '!svg-react-loader!../../content/assets/icn-estimated-tax.svg?name=TaxesIcon'

import peaceOfMindHero from '../../content/assets/img-peace-of-mind-dude.png'
import deskLady from '../../content/assets/img-desk-lady.png'
import airplaneLady from '../../content/assets/img-airplane-lady.png'


export const pageQuery = graphql`
  query {
    allMarkdownRemark(limit: 2, sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`

class HomePage extends React.Component {
  render() {
    const blogPosts = this.props.data.allMarkdownRemark.edges.map(post => {
      return <BlogPostCard post={post} key={post.node.fields.slug} />
    })
  
    return (
      <React.Fragment>
        <SEO title={`Hammock - Clarity for your freelance business`}
             description={`Clarity for your freelance business. Runway forecasting, expense tracking and estimated quarterly taxes for full-time freelancers.`}
             keywords={['freelance', 'freelancers', 'freelancing', 'freelance business', 'runway', 'freelance runway', 'freelance forecasting', 'expense tracking', 'expenses', 'freelance taxes', 'freelance quarterly estimated taxes', 'freelance tax', 'freelance hammock', 'hammock']}
        />

        <Header />

        <div className="hero-box section white px-4 px-sm-0">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-10 offset-md-1">
                <h1><strong>Clarity for your Freelance Business</strong></h1>
                <h5><strong>Helping you manage the financial ups and downs of being self-employed. </strong>Runway insights, Expense Tracking, and Estimated Quarterly Taxes for Freelancers. </h5>
                <a className="btn btn-primary" href="https://app.tryhammock.com/signup" title="Signup for a 14-day FREE Trial">
                  Signup for <strong>FREE</strong> 14-day Trial
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="our-features section grey px-4 px-sm-0">
          <div className="container">
            <div className="row">
              <div className="our-features-content col-lg-10 offset-lg-1 col-md-12 col-sm-12 col-xs-12">
                <h6 className="section-heading">Our Features</h6>
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <Link to="/features/runway" className="feature-card">
                      <RunwayIcon className="feature-icon" />
                      <p>Runway Insights</p>
                    </Link>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <Link to="/features/expense-tracking" className="feature-card">
                      <ExpensesIcon className="feature-icon" />
                      <p>Expense Tracking</p>
                    </Link>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <Link to="/features/estimated-taxes" className="feature-card">
                      <TaxesIcon className="feature-icon" />
                      <p>Estimated Taxes</p>
                    </Link>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>







        <div className="runway-insights section grey px-4 px-sm-0">
          <div className="container">
            <div className="row">
              <div className="col-md-5 pb-5">
              <h5 className="section-title">Runway Insights</h5>
                <h2>Peace of mind for freelancers</h2>
                <p>We calculate your runway based on your current account balance, projected income, expenses, taxes and goals. <strong>Know exactly when you can take a nap, and when it's time to get back to work.</strong></p>
                <a className="" href="https://app.tryhammock.com/signup" title="Signup for a 14-day FREE Trial">Sign Up for a 14-Day FREE Trial &#10230;</a>
              </div>
              <div className="col-lg-6 offset-lg-1 col-md-6 offset-md-1">
                <img className="peace-of-mind-hero" src={peaceOfMindHero} alt="When you know your runway, you can have peace of mind about your freelancing business." />
              </div>
            </div>
          </div>
        </div>


        <div className="expense-tracking section white px-4 px-sm-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <img className="desk-lady" src={deskLady} alt="When you use Hammock, tracking and categorizing business expenses is a breeze." />
              </div>
              <div className="col-lg-5 offset-lg-1 col-md-5 offset-md-1 col-sm-12 pb-5">
                <h5 className="section-title">Expense tracking</h5>
                <h2>Track and categorize expenses</h2>
                <p>Automatically track and categorize business expenses so you can keep more of what you earn. <strong>Save time and money at tax time by staying organized.</strong></p>
                <a className="" href="https://app.tryhammock.com/signup" title="Signup for a 14-day FREE Trial">Sign Up for a 14-Day FREE Trial &#10230;</a>
              </div>
            </div>
          </div>
        </div>


        <div className="estimated-taxes section grey px-4 px-sm-0">
          <div className="container">
            <div className="row">
              <div className="col-md-5 pb-5">
              <h5 className="section-title">Estimated Taxes</h5>
                <h2>Simple Quarterly Taxes</h2>
                <p>We do the math so you know what your tax bill is every quarter. <strong>Don't get caught sleeping at tax time</strong> — we'll help you avoid surprise year-end tax bills and late fines.</p>
                <a className="" href="https://app.tryhammock.com/signup" title="Signup for a 14-day FREE Trial">Sign Up for a 14-Day FREE Trial &#10230;</a>
              </div>
              <div className="col-lg-6 offset-lg-1 col-md-6 offset-md-1">
                <img className="airplane-lady" src={airplaneLady} alt="Hammock helps you visualize and determine your federal quarterly estimated tax payments." />
              </div>
            </div>
          </div>
        </div>


        <div className="campfire-blog section white px-4 px-sm-0">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="blog-home-heading mb-5">
                  <h5 className="section-title">The Hammock Blog</h5>
                  <h2>Thoughts &amp; Ideas for Freelancers</h2>
                </div>

                <div className="row">
                  {blogPosts}
                </div>
                <div className="col-md-12 campfire-btn">
                  <Link to="/blog" className="btn btn-primary">Read The Blog</Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <MailingListSignup signupLocation="Homepage Footer"/>

        <Footer />
      </React.Fragment>
    )
  }
}

export default HomePage
